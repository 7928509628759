import "./About.scss";
import worker from "../../assets/images/worker.jpg";

export const About = () => {
  document.title = `О нас | PLATUM`
  return (
    <div className="About">
      <div className="About__text">
        <h1>О компании</h1>
        <div className="About__description">
          <p>
            ООО "ПЛАТУМ" – ваш надежный партнер в сфере увеличения нефтеотдачи
            пластов с трудноизвлекаемыми запасами. Мы являемся разработчиком и
            интегратором передовых термохимических методов в нефтедобывающей
            отрасли.
          </p>
          <p>
            Наша компания специализируется на оказании услуг по активации и
            возобновлению работ нефтяных скважин, предоставляя
            высококачественные и инновационные решения, направленные на
            повышение эффективности нефтедобычи.
          </p>
          <p>
            Технология термохимического воздействия на пласт разогревающим
            составом – это результат многолетней работы и научных исследований,
            выполненных нашей командой. Мы гордимся созданием и внедрением
            инновационных технологий, которые значительно повышают эффективность
            нефтедобычи.
          </p>
          <p>
            В основе нашего успеха – профессиональные управленцы, научные
            сотрудники и инженеры с многолетним опытом работы в нефтегазовой
            отрасли. Мы постоянно стремимся к профессиональному росту и
            совершенствованию для обеспечения максимальной результативности
            наших проектов.
          </p>
          <p>
            Миссия нашей компании заключается в предоставлении
            высококачественных услуг и инновационных решений в нефтегазовой
            отрасли. Мы стремимся быть надежным партнером, поддерживая высокие
            стандарты этики, ответственности и экологической безопасности.
          </p>
          <p>
            Наши приоритеты – создание и внедрение новых технологий,
            направленных на решение конкретных задач наших клиентов. Мы работаем
            над сокращением издержек, повышением безопасности и эффективности
            операций наших клиентов, помогая им достичь максимального успеха в
            своих проектах.
          </p>
        </div>
      </div>

      <div className="worker">
        <img src={worker} alt="рабочий"></img>
      </div>
    </div>
  );
};
